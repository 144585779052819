import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase';

const SignupOptions = () => {
  const navigate = useNavigate();

  const handleGoogleSignup = async () => {
    try {
      await signInWithPopup(auth, provider);
      const nickname = localStorage.getItem('nickname');
      if (!nickname) navigate('/nickname');
      else navigate('/');
    } catch (error) {
      console.error('Google sign-up failed:', error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen + bg-gradient-to-b from-[#e6fffa] to-[#f9fafb] text-center px-6">
      <h1 className="text-3xl font-bold mb-3 text-transparent bg-clip-text bg-gradient-to-r from-[#a855f7] to-[#5eead4]">MitraAI</h1>
      <button
        onClick={handleGoogleSignup}
        //className="w-full max-w-sm bg-red-500 hover:bg-red-600 text-white py-2 rounded mb-4"
        className="w-full max-w-sm bg-gradient-to-r from-[#f3c7f7] to-[#e0dfff] text-[#6b21a8] py-3 rounded-lg mb-4 shadow-md transition-transform duration-300 hover:scale-105"
      >
        Sign up with Email
      </button>
      <button
        onClick={() => navigate('/signup-email')} 
        //className="w-full max-w-sm bg-purple-600 hover:bg-purple-700 text-white py-2 rounded mb-6"
        className="w-full max-w-sm bg-gradient-to-br from-[#5eead4] via-[#a7f3d0] to-[#f3e8ff] text-[#0f172a] py-3 rounded-lg mb-6 shadow-md transition-transform duration-300 hover:scale-105"
      >
	    Sign up with Google
      </button>

      <p className="text-sm">
        Already have an account?{' '}
        <span
          onClick={() => navigate('/auth')}
          //className="text-purple-600 hover:underline cursor-pointer"
          className="text-[#a855f7] hover:text-[#9333ea] font-medium cursor-pointer"
        >
          Log in
        </span>
      </p>
    </div>
  );
};

export default SignupOptions;