import React, { useState } from "react";
import { FaTrash, FaEdit } from "react-icons/fa";

const JournalSidebar = ({
  entries = [],
  onNewEntry,
  onDeleteEntry,
  onSelectEntry,
  onRenameEntry,
}) => {
  const [editingId, setEditingId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");

  const handleRename = (id, title) => {
    setEditingId(id);
    setEditedTitle(title);
  };

  const handleRenameSubmit = (id) => {
    if (editedTitle.trim()) {
      onRenameEntry(id, editedTitle.trim());
    }
    setEditingId(null);
    setEditedTitle("");
  };

  return (
    <div className="w-64 bg-gray-900 text-white p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-bold">Your Entries</h3>
      </div>

      <button
        className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded w-full mb-4"
        onClick={onNewEntry}
      >
       + New Entry 
      </button>

      <div className="space-y-2 overflow-y-auto max-h-[80vh] pr-2">
        {entries.map((entry) => (
          <div
            key={entry.id}
            className="bg-gray-800 p-2 rounded hover:bg-gray-700 flex justify-between items-center"
          >
            <div
              className="flex-grow cursor-pointer"
              onClick={() => onSelectEntry(entry.id)}
            >
              {editingId === entry.id ? (
                <input
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={() => handleRenameSubmit(entry.id)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && handleRenameSubmit(entry.id)
                  }
                  className="bg-gray-700 text-white rounded px-2 py-1 w-full"
                  autoFocus
                />
              ) : (
                <span>{entry.title}</span>
              )}
            </div>
            <div className="flex items-center space-x-2 ml-2">
              <FaEdit
                className="cursor-pointer text-blue-300 hover:text-blue-500"
                onClick={() => handleRename(entry.id, entry.title)}
              />
              <FaTrash
                className="cursor-pointer text-red-400 hover:text-red-600"
                onClick={() => onDeleteEntry(entry.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JournalSidebar;
