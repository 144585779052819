import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useUser } from '../context/UserContext';
import { updateProfile } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const { theme, setTheme } = useTheme();
  const { user, setUser } = useUser();
  const [nickname, setNickname] = useState(user?.displayName || '');
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (!savedTheme) setTheme('light'); // Default to light
  }, [setTheme]);

  const handleThemeChange = (e) => {
    const newTheme = e.target.value;
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const handleNicknameUpdate = async () => {
    if (nickname.length > 10) {
      setError('Nickname must be 10 characters or less.');
      return;
    }

    try {
      await updateProfile(auth.currentUser, { displayName: nickname });
      setUser({ ...user, displayName: nickname });
      localStorage.setItem('nickname', nickname);
      setError('');
      alert('Nickname updated!');
    } catch (err) {
      console.error(err);
      setError('Failed to update nickname.');
    }
  };

  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem('nickname');
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex items-start justify-center pt-20 bg-gradient-to-b from-[#e6fffa] to-[#f9fafb] px-6">
      <div className="bg-white p-6 max-w-xl w-full rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Settings</h2>

        <div className="grid grid-cols-3 gap-4 items-center mb-4">
          <label className="font-medium">Chat Theme:</label>
          <select
            className="col-span-2 border p-2 rounded"
            value={theme}
            onChange={handleThemeChange}
          >
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>

          <label className="font-medium">Nickname:</label>
          <input
            type="text"
            className="col-span-2 border p-2 rounded"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
          {nickname.length > 10 && (
            <div className="col-span-3 text-red-600 text-sm">
              Max 10 characters allowed for nickname.
            </div>
          )}
        </div>

        {error && <p className="text-red-600 mb-2">{error}</p>}

        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={handleNicknameUpdate}
            className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
          >
            Update Nickname
          </button>

          <button
            onClick={() => setShowLogoutConfirm(true)}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Logout
          </button>
        </div>

        {showLogoutConfirm && (
          <div className="mt-4 p-4 border rounded bg-red-50">
            <p className="mb-2">Are you sure you want to logout?</p>
            <div className="flex gap-3">
              <button
                onClick={handleLogout}
                className="bg-red-600 text-white px-4 py-2 rounded"
              >
                Yes, Logout
              </button>
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="bg-gray-300 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
