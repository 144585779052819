import { useState, useEffect } from 'react';

const useChatSession = () => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const stored = localStorage.getItem('currentSessionId');
    setSessionId(stored);
  }, []);

  return { sessionId };
};

export default useChatSession;