import React, { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import useChatSession from '../hooks/useChatSession';
import { useTheme } from '../context/ThemeContext';
import EmojiPicker from 'emoji-picker-react';
import AudioInput from '../components/AudioInput';
import Lottie from 'lottie-react';
import typingAnimation from '../assets/typing-animation.json';

const ChatBox = ({ selectedMood, onMoodSelect, onUpdateSessions, initialMessages }) => {
  const [messages, setMessages] = useState(initialMessages || []);
  const [input, setInput] = useState('');
  const [mood, setMood] = useState(selectedMood || null);
  const [showExport, setShowExport] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [voiceOn, setVoiceOn] = useState(false);
  const chatRef = useRef(null);
  const { sessionId } = useChatSession();
  const { theme } = useTheme();

  const moodOptions = [
    { emoji: '😁', label: 'Excited' },
    { emoji: '😊', label: 'Happy' },
    { emoji: '😐', label: 'Neutral' },
    { emoji: '😢', label: 'Sad' },
    { emoji: '😠', label: 'Angry' },
  ];

  useEffect(() => {
    document.body.classList.toggle('dark', theme === 'dark');
  }, [theme]);

  useEffect(() => {
    setMessages([]);
    const savedMessages = localStorage.getItem(`chat-${sessionId}`);
    const savedMood = localStorage.getItem(`mood-${sessionId}`);

    if (savedMessages) setMessages(JSON.parse(savedMessages));
    else setMessages([]);

    if (savedMood) {
      setMood(savedMood);
      onMoodSelect && onMoodSelect(savedMood);
    } else {
      setMood(null);
    }
  }, [sessionId]);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem(`chat-${sessionId}`, JSON.stringify(messages));
      onUpdateSessions && onUpdateSessions(messages);
    }
  }, [messages, sessionId]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const speak = (text) => {
    if (!voiceOn) return;
    const synth = window.speechSynthesis;
    if (synth.speaking) synth.cancel();
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-IN';
    utterance.pitch = 1;
    utterance.rate = 1;
    synth.speak(utterance);
  };

  const handleMoodClick = (emoji) => {
    setMood(emoji);
    localStorage.setItem(`mood-${sessionId}`, emoji);
    if (onMoodSelect) onMoodSelect(emoji);
    const botMsg = {
      id: Date.now(),
      sender: 'bot',
      text: `Thanks for sharing how you feel. I'm here for you. What's on your mind?`,
    };
    speak(botMsg.text);
    setMessages([botMsg]);
  };

  const formatDateGroup = (dateStr) => {
    const date = new Date(dateStr);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) return 'Today';
    if (isYesterday) return 'Yesterday';
    return date.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' });
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMsg = {
      id: Date.now(),
      sender: 'user',
      text: input.trim(),
    };

    if (!localStorage.getItem(`chat-${sessionId}`)) {
      onUpdateSessions && onUpdateSessions([]);
    }

    const updatedMsgs = [...messages, userMsg];
    setMessages(updatedMsgs);
    setInput('');
    localStorage.setItem(`chat-${sessionId}`, JSON.stringify(updatedMsgs));

    try {
      setIsTyping(true);
      await new Promise((resolve) => setTimeout(resolve, 500));

      const res = await fetch('https://openrouter.ai/api/v1/chat/completions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
          'HTTP-Referer': 'https://apnathela.in',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'meta-llama/llama-3-8b-instruct:free',
          messages: [
            {
              role: 'system',
              content:
                'You are Echo, a kind, emotionally intelligent, warm, friendly India-based mental wellness companion. Always respond like a good friend using simple, cheerful language. Keep your replies concise—limit to 2 sentences. Avoid U.S. helplines.',
            },
            ...updatedMsgs.map((msg) => ({
              role: msg.sender === 'user' ? 'user' : 'assistant',
              content: msg.text,
            })),
          ],
          max_tokens: 100,
          temperature: 0.7,
        }),
      });

      const data = await res.json();
      const botReply = data.choices?.[0]?.message?.content || 'Sorry, something went wrong.';

      const botMsg = {
        id: Date.now() + 1,
        sender: 'bot',
        text: botReply,
      };

      const finalMsgs = [...updatedMsgs, botMsg];
      setMessages(finalMsgs);
      if (onUpdateSessions) onUpdateSessions(finalMsgs);
      speak(botReply);
    } catch (error) {
      console.error('Fetch failed:', error);
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now() + 2,
          sender: 'bot',
          text: 'Echo is taking a break. Please try again later.',
        },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSend();
  };

  const downloadTxt = () => {
    const content = messages.map((m) => `${m.sender}: ${m.text}`).join('\n');
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'chat.txt');
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    let y = 10;
    messages.forEach((m) => {
      doc.text(`${m.sender}: ${m.text}`, 10, y);
      y += 10;
    });
    doc.save('chat.pdf');
  };

  return (
    <div className={`relative flex flex-col h-full ${theme === 'dark' ? 'bg-[#111b21] text-white' : 'bg-gradient-to-b from-[#e6fffa] to-[#f9fafb] text-black'}`}>
      {!mood ? (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="mb-4 text-lg">How are you feeling currently?</p>
          <div className="flex space-x-4">
            {moodOptions.map((option) => (
              <button
                key={option.emoji}
                onClick={() => handleMoodClick(option.emoji)}
                className="text-3xl hover:scale-125 transition-transform"
                title={option.label}
              >
                {option.emoji}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div
            ref={chatRef}
            className="flex-1 overflow-y-auto px-6 py-4 text-[16px] border-t border-gray-300 flex flex-col"
            style={{ scrollBehavior: 'smooth' }}
          >
            <div className="flex flex-col mt-auto gap-3">
              {(() => {
                let lastDate = '';
                return messages.map((msg) => {
                  const msgDate = new Date(msg.id).toDateString();
                  const isNewDate = msgDate !== lastDate;
                  lastDate = msgDate;

                  return (
                    <React.Fragment key={msg.id}>
                      {isNewDate && (
                        <div className="text-center text-sm text-gray-500 my-2">
                          {formatDateGroup(msg.id)}
                        </div>
                      )}
                      <div className={`flex w-full ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                        <div
                          className={`p-2 ${
                            msg.sender === 'user'
                              ? 'max-w-[70%] bg-green-600 text-white self-end rounded-br-none'
                              : 'max-w-[70%] bg-gray-800 text-white self-start rounded-bl-none'
                          } rounded-xl`}
                        >
                          <div className="text-xs flex justify-between items-end gap-2">
                            <span className="text-base">{msg.text}</span>
                            <span className="whitespace-nowrap opacity-60 text-[11px]">
                              {new Date(msg.id).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                            </span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                });
              })()}
            </div>
          </div>

          <div className="p-4 flex items-center gap-2 border-t border-gray-300 bg-gray-50 dark:bg-gray-100">
            <button title="Emoji" onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="text-2xl">😊</button>
            {showEmojiPicker && (
              <div className="absolute bottom-16 left-2 z-50">
                <EmojiPicker onEmojiClick={(e) => setInput((prev) => prev + e.emoji)} />
              </div>
            )}
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type a message"
              className="flex-1 border rounded-full px-4 py-2 text-black"
            />
            <button onClick={handleSend} title="Send" className="text-purple-600 text-2xl px-1">➤</button>
            <AudioInput onTranscription={(text, isFinal) => {
              if (isFinal) {
                if (!input) {
                  setInput(text);
                  setTimeout(() => handleSend(), 500);
                } else {
                  setInput((prev) => `${prev} ${text}`);
                }
              } else {
                setInput(text);
              }
            }} />
            <button
              title={voiceOn ? "Mute Mitra" : "Unmute Mitra"}
              onClick={() => setVoiceOn(!voiceOn)}
              className="text-xl"
            >
              {voiceOn ? "🔊" : "🔇"}
            </button>
            <div className="relative">
              <button title="Export" className="text-xl" onClick={() => setShowExport(!showExport)}>🖨️</button>
              {showExport && (
                <div className="absolute bottom-10 right-0 bg-white dark:bg-gray-700 border shadow p-2 flex flex-col">
                  <button onClick={downloadTxt}>Download TXT</button>
                  <button onClick={downloadPdf}>Download PDF</button>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isTyping && (
        <div className="absolute bottom-20 left-4 w-20 h-20 z-50">
          <Lottie
            animationData={typingAnimation}
            loop
            autoplay
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      )}
    </div>
  );
};

export default ChatBox;
