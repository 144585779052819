import React, { useEffect, useState } from "react";
import JournalSidebar from "../pages/JournalSidebar";
import { useUser } from "../context/UserContext";
import { useTheme } from "../context/ThemeContext";
import AudioInput from "../components/AudioInput";

const moodOptions = [
  { emoji: "🥳", label: "Excited" },
  { emoji: "😊", label: "Happy" },
  { emoji: "😐", label: "Neutral" },
  { emoji: "😪", label: "Tired" },
  { emoji: "😢", label: "Sad" },
];

const Journal = () => {
  const { user } = useUser();
  const { theme } = useTheme();

  const [entries, setEntries] = useState([]);
  const [entryTitle, setEntryTitle] = useState("Untitled Entry");
  const [entryText, setEntryText] = useState("");
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const [mood, setMood] = useState("🥳");
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem("journalEntries") || "[]");
    setEntries(stored);
  }, []);

  const handleSave = () => {
    if (selectedEntryId) {
      const updated = entries.map((entry) =>
        entry.id === selectedEntryId
          ? { ...entry, title: entryTitle, text: entryText, mood }
          : entry
      );
      setEntries(updated);
      localStorage.setItem("journalEntries", JSON.stringify(updated));
    } else {
      const newEntry = {
        id: Date.now(),
        title: entryTitle,
        text: entryText,
        mood,
        date: new Date().toLocaleString(),
      };
      const updated = [newEntry, ...entries];
      setEntries(updated);
      localStorage.setItem("journalEntries", JSON.stringify(updated));
      setSelectedEntryId(newEntry.id);
    }

    setEntryTitle("Untitled Entry");
    setEntryText("");
    setMood("🥳");
    setSelectedEntryId(null);
  };

  const handleSelectEntry = (id) => {
    const entry = entries.find((e) => e.id === id);
    if (entry) {
      setEntryTitle(entry.title);
      setEntryText(entry.text);
      setMood(entry.mood);
      setSelectedEntryId(id);
    }
  };

  const handleRenameEntry = (id, newName) => {
    const updated = entries.map((entry) =>
      entry.id === id ? { ...entry, title: newName } : entry
    );
    setEntries(updated);
    localStorage.setItem("journalEntries", JSON.stringify(updated));
  };

  const handleNewEntry = () => {
    const newEntry = {
      id: Date.now(),
      title: "Untitled Entry",
      text: "",
      mood: "🥳",
      date: new Date().toLocaleString(),
    };

    const updated = [newEntry, ...entries];
    setEntries(updated);
    localStorage.setItem("journalEntries", JSON.stringify(updated));

    setEntryTitle(newEntry.title);
    setEntryText(newEntry.text);
    setMood(newEntry.mood);
    setSelectedEntryId(newEntry.id);
  };

  const handleDeleteEntry = (id) => {
    const updated = entries.filter((entry) => entry.id !== id);
    localStorage.setItem("journalEntries", JSON.stringify(updated));
    setEntries(updated);

    if (selectedEntryId === id) {
      setSelectedEntryId(null);
      setEntryTitle("Untitled Entry");
      setEntryText("");
      setMood("🥳");
    }
  };

  const toggleSidebar = () => {
    setShowSidebar((prev) => !prev);
  };

  return (
    <div className="flex h-screen relative bg-gradient-to-b from-[#e6fffa] to-[#f9fafb]">
      <div className="fixed top-4 left-4 z-50 md:hidden flex flex-col space-y-2">
        <button
          onClick={toggleSidebar}
          className="bg-black text-white p-2 rounded-full shadow-md"
          title="Toggle Sidebar"
        >
          <span className="text-2xl font-bold">☰</span>
        </button>
        <button
          onClick={handleNewEntry}
          className="bg-green-500 text-white p-2 rounded-full shadow-md"
          title="New Entry"
        >
          <span className="text-2xl font-bold">＋</span>
        </button>
      </div>

      {showSidebar && (
        <JournalSidebar
          entries={entries}
          onNewEntry={handleNewEntry}
          onDeleteEntry={handleDeleteEntry}
          onRenameEntry={handleRenameEntry}
          onSelectEntry={handleSelectEntry}
        />
      )}
		
      <div className="flex flex-col flex-grow p-6 bg-gray-50 border-t border-gray-300 overflow-auto">
        <h2 className="text-2xl font-semibold mb-1">
          {user?.displayName || "User"}'s thoughts
        </h2>
        <div className="text-sm text-gray-500 mb-2">
          Date: {new Date().toLocaleDateString()}
        </div>

        <div className="flex items-center mb-4 space-x-4">
          <input
            type="text"
            className="text-xl font-medium border-b-2 border-gray-300 outline-none flex-grow"
            value={entryTitle}
            placeholder="Entry Title"
            onChange={(e) => setEntryTitle(e.target.value)}
          />
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition"
          >
            Save
          </button>
        </div>

        <div className="flex space-x-4 mb-4">
          {moodOptions.map(({ emoji, label }) => (
            <button
              key={label}
              className={`text-2xl ${mood === emoji ? "ring-2 ring-purple-400 rounded-full" : ""}`}
              onClick={() => setMood(emoji)}
              title={label}
            >
              {emoji}
            </button>
          ))}
        </div>

        <textarea
          className="w-full h-80 p-4 border border-gray-300 rounded-lg resize-none"
          placeholder="Write your thoughts here..."
          value={entryText}
          onChange={(e) => setEntryText(e.target.value)}
        />

        <AudioInput onTranscription={(text) => setEntryText((prev) => prev + " " + text)} />
      </div>
    </div>
  );
};

export default Journal;