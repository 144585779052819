import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // ✅ Firestore added

const firebaseConfig = {
  apiKey: "AIzaSyBCEk7nXE5q6Rxm1b338f7rRv3ihzBi3xE",
  authDomain: "innerecho-50b80.firebaseapp.com",
  projectId: "innerecho-50b80",
  storageBucket: "innerecho-50b80.firebasestorage.app",
  messagingSenderId: "1008018277512",
  appId: "1:1008018277512:web:522ee218b436b193254b3d",
  measurementId: "G-LKR4ZE1KVN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app); // ✅ Firestore initialized

export { auth, provider, db }; // ✅ Export all
