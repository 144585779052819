import React, { useState } from 'react';
import { FaTrash, FaEdit, FaBars } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const ChatSidebar = ({
  sessions,
  currentSessionId,
  onNewChat,
  onSelectSession,
  onDeleteSession,
  onRenameSession,
}) => {
  const [renamingId, setRenamingId] = useState(null);
  const [renameText, setRenameText] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const handleRename = (id, name) => {
    setRenamingId(id);
    setRenameText(name);
  };

  const handleRenameSubmit = (id) => {
    onRenameSession(id, renameText);
    setRenamingId(null);
    setRenameText('');
  };

  // Only show sidebar on /chat route
  if (location.pathname !== '/chat') return null;

  return (
    <>
      {/* Always show toggle + "+" button */}
      <div className="fixed top-4 left-4 z-50 flex space-x-2 md:hidden">
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          className="bg-gray-900 text-white p-2 rounded-md shadow-md"
          title="Toggle Sidebar"
        >
          <FaBars />
        </button>
        <button
          onClick={onNewChat}
          className="bg-green-500 text-white p-2 rounded-md shadow-md"
          title="New Chat"
        >
          +
        </button>
      </div>

      {/* Sidebar */}
      {isOpen && (
        <div className="bg-gray-900 text-white w-72 p-4 h-screen fixed md:static z-40 overflow-y-auto">
          {/* Hide on mobile toggle */}
          <div className="hidden md:flex justify-between mb-4">
            <span className="font-bold">Your Chats</span>
            <button
              onClick={onNewChat}
              className="bg-green-500 px-3 py-1 rounded text-white"
            >
              + New Chat
            </button>
          </div>

          <ul className="space-y-2 mt-4 md:mt-0">
            {Array.isArray(sessions) && sessions.map((session) => (
              <li
                key={session.id}
                className={`p-2 rounded cursor-pointer ${
                  session.id === currentSessionId ? 'bg-gray-700' : 'bg-gray-800'
                }`}
              >
                {renamingId === session.id ? (
                  <div className="flex items-center space-x-2">
                    <input
                      value={renameText}
                      onChange={(e) => setRenameText(e.target.value)}
                      onBlur={() => handleRenameSubmit(session.id)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleRenameSubmit(session.id);
                      }}
                      className="flex-1 px-2 py-1 rounded text-black"
                      autoFocus
                    />
                  </div>
                ) : (
                  <div className="flex justify-between items-center">
                    <div onClick={() => onSelectSession(session.id)}>
                      <div className="font-normal">{session.name}</div>
                      <div className="text-xs text-gray-400">{session.timestamp}</div>
                    </div>
                    <div className="flex space-x-2 ml-2">
                      <FaEdit
                        onClick={() => handleRename(session.id, session.name)}
                        className="cursor-pointer"
                      />
                      <FaTrash
                        onClick={() => onDeleteSession(session.id)}
                        className="cursor-pointer text-red-400"
                      />
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ChatSidebar;
