import React, { useEffect, useState } from "react";

const AudioInput = ({ onTranscription }) => {
  const [listening, setListening] = useState(false);
  const [error, setError] = useState(null);
  const [permissionPromptVisible, setPermissionPromptVisible] = useState(false);

  let recognition;

  if (typeof window !== "undefined" && ("webkitSpeechRecognition" in window || "SpeechRecognition" in window)) {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        const isFinal = event.results[i].isFinal;
        if (isFinal) {
          finalTranscript += transcript;
          onTranscription(finalTranscript.trim(), true);
        } else {
          onTranscription(transcript.trim(), false);
        }
      }
    };

    recognition.onerror = (event) => {
      if (event.error === "not-allowed") {
        setPermissionPromptVisible(true);
        setTimeout(() => setPermissionPromptVisible(false), 8000);
      } else {
        setError(`Mic error: ${event.error}`);
      }
    };

    recognition.onend = () => {
      setListening(false);
    };
  }

  const toggleMic = () => {
    if (!recognition) return;
    if (!listening) {
      setError(null);
      recognition.start();
      setListening(true);
    } else {
      recognition.stop();
    }
  };

  const openMicSettings = () => {
    alert("To enable microphone access, click the lock icon near your browser's address bar, then allow mic permission.");
  };

  return (
    <div className="mt-2 flex items-center gap-2">
      <button
        onClick={toggleMic}
        className={`p-2 rounded-full ${listening ? "bg-red-500" : "bg-green-500"} text-white`}
        title={listening ? "Stop Speaking" : "Start Speaking"}
      >
        🎙️
      </button>
      {listening && <span className="text-blue-500 text-sm animate-pulse">Listening...</span>}
      {permissionPromptVisible && (
        <div className="fixed top-20 right-4 bg-yellow-100 border border-yellow-400 text-yellow-800 px-4 py-2 rounded shadow-lg z-50">
          <p><strong>Mic Access Blocked:</strong> Please allow microphone permission from your browser settings.</p>
          <button
            onClick={openMicSettings}
            className="mt-2 bg-yellow-400 hover:bg-yellow-500 text-white px-3 py-1 rounded"
          >
            How to Allow Mic Access
          </button>
        </div>
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default AudioInput;
