import React, { useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const EmailSignup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async () => {
    setError('');
    if (!email || !password || !nickname) {
      return setError('All fields are required.');
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, { displayName: nickname });
      localStorage.setItem('nickname', nickname);
      navigate('/');
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists. Please log in instead.');
      } else {
        setError('Signup failed. Please try again.');
      }
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Sign Up with Email</h2>
        {error && <p className="text-red-500 mb-4 text-sm">{error}</p>}

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full mb-4 px-3 py-2 border rounded"
        />

        <div className="relative mb-4">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border rounded pr-10"
          />
          <span
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-xl"
          >
            {showPassword ? '🙈' : '👁️'}
          </span>
        </div>

        <input
          type="text"
          placeholder="What should we call you?"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          className="w-full mb-6 px-3 py-2 border rounded"
        />

        <button onClick={handleSignup} className="w-full bg-purple-600 text-white py-2 rounded mb-4">
          Sign Up
        </button>

        <p className="text-sm text-center">
          Already have an account?{' '}
          <span onClick={() => navigate('/login-email')} className="text-purple-600 hover:underline cursor-pointer">
            Log in with Email
          </span>
        </p>
      </div>
    </div>
  );
};

export default EmailSignup;
