import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen + bg-gradient-to-b from-[#e6fffa] to-[#f9fafb] text-center px-6">
      <h1 className="text-3xl md:text-4xl font-bold mb-4">Welcome to MitraAI</h1>
      <p className="text-gray-600 text-base md:text-lg mb-6">
        Your personal mental wellness companion
      </p>
      <button
        onClick={() => navigate('/chat')}
        className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg shadow-md transition-transform transform hover:scale-105"
      >
        Talk to Mitra
      </button>
    </div>
  );
};

export default Home;